<template>
	<div>

		<b-card>
			<div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap" >
				<div class="d-flex align-items-center flex-wrap mr-1">

				</div>
				<div class="d-flex align-items-center">
					<b-button  variant="danger" @click="SubmitTemplate();" style="width: 130px;"><i class="la la-save"></i>{{$t('TEMPLATE.MSG_SAVE')}}</b-button>
				</div>
			</div>
			<br>
			<hr>
			<h3>{{$t('TEMPLATE.MSG_PAGE_DESC')}}</h3>
			<br>
			<div class="row">
				<div class="col-md-4">
					{{$t('TEMPLATE.MSG_COLOR_BTN')}}
				</div>
				<div class="col-md-8 colorpicker">
					<v-input-colorpicker  v-model="color.btn_shop.color" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					{{$t('TEMPLATE.MSG_COLOR_TXT_BTN')}}
				</div>
				<div class="col-md-8 colorpicker">
					<v-input-colorpicker  v-model="color.btn_shop.text" />
				</div>
			</div>
			<hr>
			<h3>{{$t('TEMPLATE.MSG_COLOR_CATEGORYS')}}</h3>
			<br>
			<div class="row">
				<div class="col-md-4">
					{{$t('TEMPLATE.MSG_COLOR_CATEGORY')}}
				</div>
				<div class="col-md-8 colorpicker">
					<v-input-colorpicker  v-model="color.category.color" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					{{$t('TEMPLATE.MSG_COLOR_TXT_CATEGORY')}}
				</div>
				<div class="col-md-8 colorpicker">
					<v-input-colorpicker  v-model="color.category.text" />
				</div>
			</div>
			<hr>
			<h3>{{$t('TEMPLATE.MSG_COLOR_TEMPLATE_TITLE')}}</h3>
			<br>
			<div class="row">
				<div class="col-md-12">
					{{$t('TEMPLATE.MSG_COLOR_TXT_COMING_SOON')}}
				</div>
			</div>
		</b-card>
	</div>
</template>
<style>
    .colorpicker input{
		border: 1px solid #3f427a!important;
	}
</style>
<script>
import axios from "axios"; 
import InputColorPicker from 'vue-native-color-picker'
import Swal from "sweetalert2";
export default {
	components: {
		"v-input-colorpicker": InputColorPicker,
	  },
	data: () => ({
		color:{btn_shop: {color: "#000000", text: "#ffffff"}, category: {color: "#ff0000", text: "#ffffff"}},
	}),
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		var tpl = localStorage.getItem('TemplateSettings')
		this.color = JSON.parse(tpl);
		this.GetTemplate();
	},
	methods: {
		SubmitTemplate() {
			var data = {config:this.color};
			console.log(data);
			axios.post("/klik/customer/template",data).then((response) => {
				console.log(response);
				Swal.fire({
					title: this.$t("APP_NAME"),
					text: this.$t("TEMPLATE.MSG_SUCCESS_OPERATION"),
					icon: "info",
					confirmButtonClass: "btn btn-secondary",
					heightAuto: false
				});
			}).catch((errors) => {
				Swal.fire({
					title: this.$t("APP_NAME"),
					text: this.$t("TEMPLATE.MSG_ERROR_HAS_OCCURRED"),
					icon: "info",
					confirmButtonClass: "btn btn-secondary",
					heightAuto: false
				});
				console.log(errors);
			});
		},
		GetTemplate() {
			var tpl = localStorage.getItem('TemplateSettings')
			this.color = JSON.parse(tpl);
		},
	},
}
</script>
